import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { MYACCOUNT_THEME } from "@myaccountUrls";

const collectFormValues = (ref, params) => {
    const formsdata = ref.current
    const values = {}
    Object.keys(formsdata).map(key => {
        if (key && formsdata[key].value && formsdata[key].name) {
            if (formsdata[key].type === "file") {
                if (formsdata[key].files.length) {
                    return (values[formsdata[key].name] =
                        formsdata[key].files[0])
                }
            } else if (formsdata[key].type === "tel") {
                if (formsdata[key].value && isPossiblePhoneNumber(formsdata[key].value))
                    return (values[formsdata[key].name] = formsdata[key].value)
                return false;
            } else if (formsdata[key].type === "radio") {
                if (formsdata[key].checked)
                    return (values[formsdata[key].name] = formsdata[key].value)
            } else {
                return (values[formsdata[key].name] = formsdata[key].value)
            }
        } else return false
    })
    return {
        ...params,
        ...values,
    }
}

const validateFormFields = (ref, formJson, boolRes=false) => {
    const formsdata = ref.current
    let error = false;
    Object.keys(formsdata).map(key => {
        const field = formsdata[key]
        const fieldName = field.name
        const fieldValue = field.value
        let fieldErrorMessage = '';
        fieldErrorMessage = field.validationMessage
        const index = formJson.findIndex(x => x.name === fieldName)
        let inputPropsTemp = {}
        // Look for inputProps
        if (formJson[index]?.inputProps) {
            inputPropsTemp = { ...formJson[index].inputProps }
        }

        if (fieldValue && field.type !== "file" && field.type !== "radio") {
            inputPropsTemp["defaultValue"] = fieldValue
        } else if (fieldValue && field.type === "radio") {
            if (field.checked)
                inputPropsTemp["defaultValue"] = fieldValue
        }

        if (fieldValue && field.type === "tel") {
            if (!isPossiblePhoneNumber(fieldValue)) {
                fieldErrorMessage = 'Enter valid phone number with country code';
            }
        }
        
        if(inputPropsTemp.limit){
            if(fieldValue.length > inputPropsTemp.limit){
                fieldErrorMessage = `Enter your ${formJson[index].label} below ${inputPropsTemp.limit} characters`;
            }   
        }

        if (fieldErrorMessage) {
            if (error === false)
                error = true;
            formJson[index] = {
                ...formJson[index],
                inputProps: { ...inputPropsTemp },
                ...inputPropsTemp,
                helperText: formJson[index]?.helperText ? formJson[index]?.helperText : fieldErrorMessage,
                error: true,
            }
        } else {
            // if(fieldValue && field.type !== "radio") {
            formJson[index] = {
                ...formJson[index],
                inputProps: { ...inputPropsTemp },
                ...inputPropsTemp
            }
            // }
        }
    })

    if (boolRes)
        return error;

    return formJson
}

function isRequiredFieldHasValues(fields) {
    let success = true;
    Object.keys(fields).map(key => {
        const field = fields[key]
        const required = field.required
        const fieldValue = field.value || field.inputProps.defaultValue
        if (required && !fieldValue && success) {
            success = false;
        }
    })
    return success;
}

const getFormFields = path => {
    const defaultData = require(`../Forms/default/${path}`)
    try {
        let projectData = require(`../Forms/${MYACCOUNT_THEME}/${path}`)
        if (projectData)
            return projectData;
    } catch (err) {}

    return defaultData;
}

export function getFieldPrefix(fields) {
    let data = {}
    Object.entries(fields).map(([key, value]) => {
        let splitData = key.split(/_(.+)/)
        if (!data[splitData[0]])
            data[splitData[0]] = {};
        if (splitData[1])
            data[splitData[0]][splitData[1]] = value;
        else
            data[splitData[0]] = value;
    })

    return data;
}

export { collectFormValues, validateFormFields, isRequiredFieldHasValues, getFormFields }
